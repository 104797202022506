import { createStyles, makeStyles } from '@mui/styles';

export const appStyle = makeStyles(() =>
    createStyles({
        app_container: {
            width: '100%',
            height: '100%',
            overflow: 'auto',
            paddingTop: '2em'
        },
        inner_container: {
            height: '60.745em',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center -10em'
        },
        middle_container: {
            display: 'flex',
            width: '93.267em',
            height: '44.768em',
            margin: 'auto',
            position: 'relative'
        },
        animation_container: {
            position: 'relative',
            overflow: 'hidden',
            height: '13.5em',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            backgroundSize: 'contain'
        },
        cart_for_mines_container: {
            position: 'absolute',
            left: '-18%',
            visibility: 'hidden',
            height: '100%',
            width: '17.25em'
        },
        cart_for_mines_container_animation: {
            visibility: 'visible',
            left: '-18%',
            animation: '$step 2s cubic-bezier(0.09, -0.01, 0.48, 1.05)'
        },
        cart_for_mines: {
            width: '17.5em',
            height: '11.25em'
        },
        cart_for_mines_animation: {
            top: '-6px',
            left: '0',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '-0.75em',
            backgroundPositionY: '-3.8em',
            backgroundSize: 'auto 160%',
            animation: '$play 0.8s steps(26)'
        },
        loadingContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        loading: {
            width: '16%',
            height: '16%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain'
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
            middle_container: {
                marginTop: '7.4194em',
                height: '93.936em',
                width: '93.936em'
            },
            inner_container: {
                height: '136em',
                backgroundPosition: 'center 50em'
            },
            animation_container: {
                backgroundSize: 'auto 64%',
                marginTop: '13em',
                height: '20em'
            },
            cart_for_mines: {
                width: '21.5em',
                height: '14.25em'
            },
            cart_for_mines_container: {
                width: '21.5em'
            },
            cart_for_mines_animation: {
                backgroundSize: 'auto 160%',
                backgroundPositionX: '-0.75em',
                backgroundPositionY: '-4.8em',
                animation: '$playMobile 0.8s steps(26)'
            },
            '@keyframes playMobile': {
                '100%': {
                    backgroundPositionX: '-593em'
                }
            }
        },
        '@media screen and (max-width: 480px) and (orientation: portrait)': {
            app_container: {
                paddingTop: 0
            },
            middle_container: {
                marginTop: '1.9999em',
                height: '111.336em',
                width: '107.999999em'
            },
            inner_container: {
                height: '160em'
            },
            animation_container: {
                backgroundSize: 'auto 64%',
                marginTop: '13em',
                height: '20em'
            },
            cart_for_mines: {
                width: '21.5em',
                height: '14.25em'
            },
            cart_for_mines_container: {
                width: '21.5em'
            },
            cart_for_mines_animation: {
                backgroundSize: 'auto 160%',
                backgroundPositionX: '-0.75em',
                backgroundPositionY: '-4.8em',
                animation: '$playMobile 0.8s steps(26)'
            },
            '@keyframes playMobile': {
                '100%': {
                    backgroundPositionX: '-593em'
                }
            }
        },

        '@keyframes play': {
            '100%': {
                backgroundPositionX: '-468em'
            }
        },

        '@keyframes step': {
            '100%': {
                left: ' 100%'
            }
        }
    })
);
