import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../../utils/colors';

export const attentionModalStyle = makeStyles(() =>
    createStyles({
        container: {
            width: '22.4em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.06em',
            backgroundColor: colors.manganese_black,
            borderRadius: '1.06em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                width: '90%',
                padding: 16,
                borderRadius: 16,
                margin: '0 auto'
            }
        },
        icon: {
            marginBottom: '0.93em',
            marginTop: '3.2em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                marginBottom: 12,
                marginTop: 48
            }
        },
        title: {
            fontSize: '1.2em',
            color: colors.white,
            marginBottom: '0.4em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                fontSize: 18,
                marginBottom: 4
            }
        },
        info: {
            fontSize: '1.06em',
            color: colors.blue_Oar,
            marginBottom: '3.06em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                fontSize: 16,
                marginBottom: 46
            }
        },
        buttonsWrap: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        button: {
            width: '49%',
            height: '3.2em',
            backgroundColor: colors.epic_blue,
            color: colors.white,
            fontSize: '0.93em',
            borderRadius: '0.26em',
            cursor: 'pointer',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                fontSize: 14,
                height: 48,
                borderRadius: 4
            }
        },
        reload_button: {
            width: '100%',
            height: '3.2em',
            backgroundColor: colors.epic_blue,
            color: colors.white,
            fontSize: '0.93em',
            borderRadius: '0.26em',
            cursor: 'pointer',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                fontSize: 14,
                height: 48,
                borderRadius: 4
            }
        },
        price: {
            color: colors.gold
        }
    })
);
