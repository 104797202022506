import { type ClassNameMap } from '@mui/styles';
import React, { type ChangeEvent, type JSX, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';

import { menuStyle } from './menu-style';
import minesDecoration from '../../assets/images/levelResultExplanationDecoration.svg';
import diamondDecoration from '../../assets/images/levelResultMinesDecoration.svg';
import Select from '../../cors/select/select';
import SmallButton from '../../cors/small-button/small-button';
import { formatNumberWithoutRound } from '../../helpers/format-number';
import { socket } from '../../socket';
import { type GameState, updateBetAmount } from '../../store/gameSlice';
import BetLimitModal from '../modal/attention-modal/bet-limit-modal';
import InfluenceBalance from '../modal/attention-modal/influence-balance';

export default function Menu({
    isDemo,
    fontSize
}: {
    isDemo: boolean;
    fontSize: number;
}): JSX.Element {
    const classes: ClassNameMap = menuStyle();

    const {
        betAmount,
        isGameStarted,
        canPlay,
        token,
        partnerId,
        balance,
        langText
    } = useSelector((state: { game: GameState }) => state.game);

    const {
        defaultSelectCount,
        favoriteCounts,
        minesCountList,
        minesCount,
        nextCoefficient,
        matrixSize,
        stake
    } = useSelector((state: { game: GameState }) => state.game.gameInfo);

    const { currencyId, maxBet, minBet } = useSelector(
        (state: { game: GameState }) => state.game.partnerInfo
    );

    const [insufficientBalanceModalOpen, setInsufficientBalanceModalOpen] =
        useState(false);

    const dispatch = useDispatch();

    function onlyNumberKey(
        event: React.CompositionEvent<HTMLInputElement>
    ): void {
        const value: string =
            (event.target as HTMLInputElement).value.replace(/,/g, '') +
            event.data;

        if (!/^[0-9]\d*(\.\d*)?$/.test(value)) {
            event.preventDefault();
        }
    }

    function toggleGame(): void {
        isGameStarted
            ? socket.emit('cashOut', {
                stakeId: stake?.stakeId,
                token
            })
            : betAmount > balance || minBet > betAmount || betAmount > maxBet
                ? setInsufficientBalanceModalOpen(true)
                : socket.emit('startGame', {
                    partnerId,
                    token,
                    isDemo,
                    matrixSize,
                    minesCount,
                    amount: betAmount
                });
    }

    function selectIem(numberOfMines: number): void {
        socket.emit('getCoefficients', {
            minesCount: numberOfMines,
            partnerId
        });
    }

    function updateAmount(event: ChangeEvent<HTMLInputElement>): void {
        dispatch(updateBetAmount(Number(event.target.value.replace(/,/g, ''))));
    }

    return (
        <div className={classes.menuContainer}>
            <div>
                <p className={classes.title}>
                    {langText?.count_of_mines ?? 'Count of Mines'}
                </p>
                {isGameStarted
                    ? (
                        <div className={classes.buttonsWrapper}>
                            <div className={classes.countBox}>
                                <p className={classes.countBoxTitle}>
                                    {minesCount}
                                </p>
                                <img
                                    className={classes.countBoxImage}
                                    src={minesDecoration}
                                    alt="icon"
                                />
                            </div>
                            <div className={classes.countBox}>
                                <p className={classes.countBoxTitle}>
                                    {matrixSize[0] * matrixSize[1] - minesCount}
                                </p>
                                <img
                                    className={classes.countBoxImage}
                                    src={diamondDecoration}
                                    alt="icon"
                                />
                            </div>
                        </div>
                    )
                    : (
                        <div className={classes.buttonsWrapper}>
                            {favoriteCounts.map((item, index) => (
                                <SmallButton
                                    key={index}
                                    disabled={!canPlay}
                                    title={item}
                                    isActive={minesCount === item}
                                    onClick={() => {
                                        selectIem(item);
                                    }}
                                />
                            ))}
                            <Select
                                disabled={!canPlay}
                                defaultCount={defaultSelectCount}
                                isSelected={!favoriteCounts.includes(minesCount)}
                                value={minesCount}
                                items={minesCountList}
                                onSelect={selectIem}
                            />
                        </div>
                    )}
            </div>
            <div>
                <div className={classes.infoWrapper}>
                    <p className={classes.stake}>
                        {langText?.stake ?? 'Stake'}
                    </p>
                    <p className={classes.info}>x{nextCoefficient}</p>
                </div>
                <div className={classes.inputWrapper}>
                    <input
                        disabled={isGameStarted || !canPlay}
                        value={formatNumberWithoutRound(betAmount)}
                        onChange={updateAmount}
                        className={classes.input}
                        onBeforeInput={onlyNumberKey}
                    />
                    <p className={classes.fun}>{isDemo ? 'FUN' : currencyId}</p>
                </div>
                <button
                    className={classes.button}
                    onClick={toggleGame}
                    disabled={!canPlay || (isGameStarted && stake === null)}
                >
                    {isGameStarted
                        ? langText?.cash_out ?? 'Cash out'
                        : langText?.place_a_bet ?? 'Place a Bet'}
                </button>
            </div>
            <Modal
                showCloseIcon={false}
                blockScroll={false}
                center={true}
                onClose={() => undefined}
                open={insufficientBalanceModalOpen}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                styles={{
                    modal: {
                        margin: 0,
                        padding: 0,
                        overflowY: 'unset',
                        background: 'transparent'
                    },
                    modalContainer: { fontSize, height: '60.745em' }
                }}
            >
                {betAmount > balance
                    ? (
                        <InfluenceBalance
                            header={
                                langText?.insufficient_balance ??
                            'Insufficient balance'
                            }
                            content={
                                langText?.increase_balance ??
                            'You can increase your balance'
                            }
                            onCancel={(): void => {
                                setInsufficientBalanceModalOpen(false);
                            }}
                        />
                    )
                    : (
                        <BetLimitModal
                            header={langText?.bet_limit ?? 'Bet Limit'}
                            content={
                                minBet > betAmount
                                    ? langText?.min_bet_info ??
                                  `Minimum bet starts from ${
                                      isDemo ? 'FUN' : currencyId
                                  } ${minBet}`
                                    : langText?.max_bet_info ??
                                  `Maximum bet is ${
                                      isDemo ? 'FUN' : currencyId
                                  } ${minBet}`
                            }
                            onCancel={(): void => {
                                setInsufficientBalanceModalOpen(false);
                            }}
                        />
                    )}
            </Modal>
        </div>
    );
}
