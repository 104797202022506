export function formatNumberWithoutRound(number, decimals = 0, decPoint = '.', thousandsSep = ',') {
    const newNumber = `${number}`.replace(/[^0-9+\-Ee.]/g, '');
    const n = !Number.isFinite(+newNumber) ? 0 : +newNumber;
    const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
    let s = '';

    const toFixedFix = function (nNumber, pPrec) {
        const splintedNumber = nNumber.toString().split('.');
        let formattedNumber = nNumber;

        if (splintedNumber.length > 1) {
            formattedNumber = `${splintedNumber[0]}${decPoint}${splintedNumber[1].slice(0, pPrec)}`;
        } else {
            formattedNumber = parseFloat(formattedNumber).toFixed(pPrec);
        }
        return formattedNumber;
    };

    s = (prec ? toFixedFix(n, prec).toString() : `${Math.round(n)}`).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(decPoint);
}

