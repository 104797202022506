import React, { type JSX, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';

import { appStyle } from './app-style';
import loader from './assets//images/loading.svg';
import AnimationCarriage from './assets/images/animationCarriage.png';
import Background from './assets/images/BGDecoration.png';
import SpriteSheet from './assets/images/spritesheet.png';
import GameSection from './components/game-section/game-section';
import InternetConnectionModal from './components/modal/attention-modal/internet-connection-modal';
import DescriptionModal from './components/modal/description-modal/description-modal';
import Settings from './components/settings/settings';
import calculateFont from './helpers/calculate-font';
import 'react-responsive-modal/styles.css';
import {
    type FinishGameBody,
    type GameBody,
    type GetCoefficientsBody,
    type OpenCardBody,
    type StartGameBody
} from './models';
import { socket } from './socket';
import {
    finishGame,
    type GameState,
    initializeGame,
    openCard, resetGame,
    startGame,
    toggleAllowAnimation,
    toggleConnection,
    updateCoefficients
} from './store/gameSlice';

function App(): JSX.Element {
    const classes = appStyle();
    const dispatch = useDispatch();
    const [fontSize, setFontSize] = useState(0);
    const [isOffline, setIsOffline] = useState(false);
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    const isDemo: boolean = (searchParams.get('isDemo') ?? 'false') === 'true';

    const { allowAnimation, isConnected, langText } = useSelector(
        (state: { game: GameState }) => state.game
    );

    const [descriptionModal, setDescriptionModal] = useState(false);

    function handleResize(): void {
        setTimeout(() => {
            setFontSize(calculateFont());
        }, 100);
    }

    function handleContextMenu(event: Event): void {
        // event.preventDefault();
    }

    function handleAnimationEnd(event: Event): void {
        if (
            (event.target as HTMLElement).classList.contains(
                classes.cart_for_mines_container
            )
        ) {
            dispatch(toggleAllowAnimation(false));
        }
    }

    function setOffline(): void {
        setIsOffline(true);
    }

    function setOnline(): void {
        setIsOffline(false);
    }

    function onConnect(): void {
        dispatch(toggleConnection(true));

        socket.emit('getInitialState', {
            culture: searchParams.get('locale') ?? 'en',
            isDemo,
            partnerId: Number(searchParams.get('partnerId')) ?? 0,
            token: searchParams.get('token') ?? ''
        });
    }

    function onDisconnect(): void {
        dispatch(toggleConnection(false));
        window.location.reload();
    }

    function onGetInitialState(item: GameBody): void {
        if (item.validation === 0) {
            dispatch(initializeGame(item));
        }
    }

    function onStartGame(item: StartGameBody): void {
        if (item.validation === 0) {
            dispatch(startGame(item));
        }
    }

    function onGetCoefficients(item: GetCoefficientsBody): void {
        if (item.validation === 0) {
            dispatch(updateCoefficients(item));
        }
    }

    function onOpenCard(item: OpenCardBody): void {
        if (item.validation === 0) {
            dispatch(openCard(item));
        }
    }

    function onFinishGame(item: FinishGameBody): void {
        if (item.validation === 0) {
            dispatch(finishGame(item));
            setTimeout((): void => { dispatch(resetGame()); }, 2000);
        }
    }

    useEffect(() => {
        setFontSize(calculateFont);
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);
        window.addEventListener('contextmenu', handleContextMenu);
        window.addEventListener('resize', handleResize);
        window.addEventListener('animationend', handleAnimationEnd);

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('finishGame', onFinishGame);
        socket.on('getCoefficients', onGetCoefficients);
        socket.on('getInitialState', onGetInitialState);
        socket.on('openCard', onOpenCard);
        socket.on('startGame', onStartGame);

        return function cleanup() {
            document.removeEventListener('offline', setOffline);
            document.removeEventListener('online', setOnline);
            document.removeEventListener('resize', handleResize);
            document.removeEventListener('animationend', handleAnimationEnd);
            document.removeEventListener('contextmenu', handleContextMenu);
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('finishGame', onFinishGame);
            socket.off('getCoefficients', onGetCoefficients);
            socket.off('getInitialState', onGetInitialState);
            socket.off('openCard', onOpenCard);
            socket.off('startGame', onStartGame);
        };
    }, []);

    return (
        <div className={classes.app_container} style={{ fontSize }}>
            {isConnected
                ? <div
                    className={classes.inner_container}
                    style={{ backgroundImage: `url(${Background})`, fontSize }}
                >
                    <section
                        className={classes.middle_container}
                        style={{ fontSize }}
                    >
                        <GameSection fontSize={fontSize} isDemo={isDemo}/>
                        <Settings
                            fontSize={fontSize}
                            onDescription={() => {
                                setDescriptionModal(true);
                            }}
                        />
                    </section>
                    <section
                        className={classes.animation_container}
                        style={{
                            backgroundImage: `url(${AnimationCarriage})`,
                            fontSize
                        }}
                    >
                        <div
                            className={`${classes.cart_for_mines_container} ${
                                allowAnimation
                                    ? classes.cart_for_mines_container_animation
                                    : ''
                            }`}
                        >
                            <div
                                className={`${classes.cart_for_mines} ${
                                    allowAnimation
                                        ? classes.cart_for_mines_animation
                                        : ''
                                }`}
                                style={{ backgroundImage: `url(${SpriteSheet})` }}
                            />
                        </div>
                    </section>
                </div>
                : <div className={classes.loadingContainer}>
                    <div className={classes.loading} style={{ backgroundImage: `url(${loader})` }} />
                </div>
            }

            <Modal
                blockScroll={false}
                center={true}
                onClose={() => {
                    setDescriptionModal(false);
                }}
                open={descriptionModal}
                styles={{
                    modal: {
                        margin: 0,
                        padding: 0,
                        overflowY: 'unset',
                        background: 'transparent'
                    },
                    modalContainer: { fontSize, height: window.innerWidth > 767 ? '60.745em' : '100%' }
                }}
                showCloseIcon={false}
            >
                <DescriptionModal
                    description={langText?.info}
                    onClose={() => {
                        setDescriptionModal(false);
                    }}
                />
            </Modal>
            <Modal
                showCloseIcon={false}
                blockScroll={false}
                center={true}
                onClose={() => undefined}
                open={isOffline}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                styles={{
                    modal: {
                        margin: 0,
                        padding: 0,
                        overflowY: 'unset',
                        background: 'transparent',
                        width: window.innerWidth < 767 ? '100%' : 'unset'
                    },
                    modalContainer: { fontSize, height: window.innerWidth > 767 ? '60.745em' : '100%' }
                }}
            >
                <InternetConnectionModal />
            </Modal>
        </div>
    );
}

export default App;
