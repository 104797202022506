import { type ClassNameMap } from '@mui/styles';
import React, { type JSX } from 'react';
import { useSelector } from 'react-redux';

import { attentionModalStyle } from './attention-modal-style';
import warningIcon from '../../../assets/images/warning.svg';
import { type GameState } from '../../../store/gameSlice';
import { colors } from '../../../utils/colors';

export default function InfluenceBalance({
    onCancel,
    header,
    content
}: {
    onCancel?: () => void;
    header: string;
    content: string;
}): JSX.Element {
    const classes: ClassNameMap = attentionModalStyle();

    const { langText } = useSelector(
        (state: { game: GameState }) => state.game
    );

    return (
        <div className={classes.container}>
            <img className={classes.icon} src={warningIcon} alt={'icon'} />
            <p className={classes.title}>{header}</p>
            <p className={classes.info}>{content}</p>
            <div className={classes.buttonsWrap}>
                <button
                    style={{ backgroundColor: colors.monaco_blue }}
                    className={classes.button}
                    onClick={onCancel}
                >
                    {langText?.cancel ?? 'Cancel'}
                </button>
                <button className={classes.button}>{langText?.increase ?? 'Increase'}</button>
            </div>
        </div>
    );
}
