import { combineReducers, configureStore } from '@reduxjs/toolkit';

import gameReducer from './gameSlice';

const rootReducer = combineReducers({
    game: gameReducer
});

const store = configureStore({
    reducer: rootReducer
});

export default store;
