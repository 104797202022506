import { type ClassNameMap } from '@mui/styles';
import { type PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import React, { type JSX, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';

import { settingsStyle } from './settings-style';
import explanation from '../../assets/images/explanationIcon.svg';
import settings from '../../assets/images/settings.svg';
import { type GameState, toggleSound, toggleVoice } from '../../store/gameSlice';
import { colors } from '../../utils/colors';

interface SettingsProps {
    onDescription?: () => void;
    fontSize: number;
}

export default function Settings({ onDescription, fontSize }: SettingsProps): JSX.Element {
    const classes: ClassNameMap = settingsStyle();
    const { voice, sound, langText } = useSelector((state: { game: GameState }) => state.game);
    const dispatch = useDispatch();
    const [settingsModal, setSettingsModal] = useState(false);
    const scale: number = (window.innerWidth < 768 && window.innerWidth < window.innerHeight) ? 4 : 16;

    const setVoice = (): PayloadAction<any, `${string}/${string}`> | PayloadAction<any, `${string}/${string}`, never, never> => dispatch(toggleVoice());
    const setSound = (): PayloadAction<any, `${string}/${string}`> | PayloadAction<any, `${string}/${string}`, never, never> => dispatch(toggleSound());

    return (

        <div className={classes.settings_container}>
            <div className={classes.settings_box}>
                <button
                    onClick={() => { setSettingsModal(!settingsModal); }}
                    className={classes.settings_button}
                    style={{ backgroundImage: `url(${settings})` }}
                />
                {settingsModal && (<div className={classes.settings_pop_up}>
                    <div className={classes.settings_box_item}>
                        <p className={classes.settings_box_item_text}>{langText?.sound ?? 'Sound'}</p>
                        <Switch
                            checked={sound}
                            onChange={setSound }
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor={colors.elite_blue}
                            offColor={colors.hei_se_black}
                            onColor={colors.pool_water}
                            onHandleColor={colors.crocus_Purple}
                            width={(48 / scale) * fontSize}
                            height={(21 / scale) * fontSize}
                        />
                    </div>
                    <div className={classes.settings_box_item}>
                        <p className={classes.settings_box_item_text}>{langText?.voice ?? 'Voice'}</p>
                        <Switch
                            checked={voice}
                            onChange={setVoice }
                            checkedIcon={false}
                            uncheckedIcon={false}
                            offHandleColor={colors.elite_blue}
                            offColor={colors.hei_se_black}
                            onColor={colors.pool_water}
                            onHandleColor={colors.crocus_Purple}
                            width={(48 / scale) * fontSize}
                            height={(21 / scale) * fontSize}
                        />
                    </div>
                </div>)}
            </div>
            <div>
                <button onClick={onDescription} className={classes.settings_button} style={{ backgroundImage: `url(${explanation})` }}></button>
            </div>
        </div>
    );
}
