import { type ClassNameMap } from '@mui/styles';
import React, { type JSX } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { gameSectionStyle } from './game-section-style';
import Decoration1 from '../../assets/images/decoration1.png';
import { socket } from '../../socket';
import { type GameState, togglePlaceBetTextShown } from '../../store/gameSlice';
import Card from '../card/card';
import Menu from '../menu/menu';

export default function GameSection({
    isDemo,
    fontSize
}: {
    isDemo: boolean;
    fontSize: number;
}): JSX.Element {
    const classes: ClassNameMap = gameSectionStyle();
    const dispatch = useDispatch();

    const {
        langText,
        currentCoefficient,
        isGameStarted,
        isPlaceBetTextShown,
        isPlaceBetTextVisible,
        showWinDialog,
        token,
        betAmount,
        partnerId
    } = useSelector((state: { game: GameState }) => state.game);

    const { cardsArray, matrixSize, stake } = useSelector(
        (state: { game: GameState }) => state.game.gameInfo
    );

    const { currencyId } = useSelector(
        (state: { game: GameState }) => state.game.partnerInfo
    );

    function checkCard(boxNumber: number): void {
        if (stake === null) {
            socket.emit('addStake', {
                partnerId,
                isDemo,
                bet: betAmount,
                token,
                betType: 1,
                boxNumber
            });
        } else {
            socket.emit('openCard', {
                stakeId: 1,
                token,
                boxNumber
            });
        }
    }

    function togglePlaceBetLayer(): void {
        if (isPlaceBetTextVisible) return;
        dispatch(togglePlaceBetTextShown());

        setTimeout(() => {
            dispatch(togglePlaceBetTextShown());
        }, 1000);
    }

    return (
        <div className={classes.game_section}>
            <div className={classes.game_section_header}>
                <p className={classes.game_section_header_title}>Minesweeper</p>
            </div>
            <div className={classes.game_body}>
                <div
                    className={classes.left_image}
                    style={{ backgroundImage: `url(${Decoration1})` }}
                ></div>
                <div className={classes.card_wrapper}>
                    {cardsArray.map((status: string, index: number) => (
                        <Card
                            disabled={!isGameStarted || status !== ''}
                            status={status}
                            key={index}
                            width={`calc(${100 / matrixSize[0]}% - var(--gap)`}
                            height={`calc(${100 / matrixSize[1]}% - var(--gap)`}
                            onclick={(): void => {
                                checkCard(index);
                            }}
                        />
                    ))}
                    {showWinDialog
                        ? (
                            <div className={`${classes.pop_up} bounceIn`}>
                                <div className={classes.pop_up_block}>
                                    <p className={classes.pop_up_title}>
                                    X{currentCoefficient}
                                    </p>
                                    <p className={classes.pop_up_info}>
                                        {stake?.possibleWin ?? 0}{' '}
                                        {isDemo ? 'FUN' : currencyId}
                                    </p>
                                </div>
                            </div>
                        )
                        : null}
                    {isPlaceBetTextShown
                        ? (
                            <div
                                className={classes.pop_up}
                                onClick={togglePlaceBetLayer}
                            >
                                {isPlaceBetTextVisible && (
                                    <p className={classes.place_bet_to_start}>
                                        {langText?.place_bet_to_start ??
                                        'Place Bet To Start'}
                                    </p>
                                )}
                            </div>
                        )
                        : null}
                </div>
                <div className={classes.bet_bar}>
                    <Menu fontSize={fontSize} isDemo={isDemo} />
                </div>
            </div>
        </div>
    );
}
