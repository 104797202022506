import mine from '../assets/audio/Bomb.mp3';
import cashOut from '../assets/audio/Cashout.mp3';
import diamond from '../assets/audio/Dimonds.mp3';
import lose from '../assets/audio/lose.mp3';
import makeItHappen from '../assets/audio/Make_it_happen_paly.mp3';
import placeBet from '../assets/audio/Place_bet_1.mp3';
import win from '../assets/audio/Win.mp3';
export const audioFiles = {
    mine,
    cashOut,
    diamond,
    lose,
    makeItHappen,
    placeBet,
    win
};
