import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../../utils/colors';

export const descriptionModalStyle = makeStyles(() =>
    createStyles({
        container: {
            width: '32.875em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                width: '90%',
                margin: '0 auto'
            }
        },
        modalHeader: {
            backgroundColor: colors.kon,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.8em 0.8em 0.8em 1.6em',
            borderTopLeftRadius: '1.06em',
            borderTopRightRadius: '1.06em',
            marginBottom: '0.125em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                padding: '6px 16px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                marginBottom: '1px'
            }
        },
        title: {
            color: colors.cold_wind,
            fontSize: '1.2em',
            fontWeight: 'bold',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                fontSize: 14
            }

        },
        modalBody: {
            maxHeight: '39.125em',
            backgroundColor: colors.kon,
            overflow: 'auto',
            padding: '1.6em',
            fontSize: '0.93em',
            fontWeight: '500',
            color: colors.masterpiece,
            borderBottomLeftRadius: '1.06em',
            borderBottomRightRadius: '1.06em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                maxHeight: '60vh',
                padding: 16,
                fontSize: 12,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8
            }
        },
        text: {
            paddingBottom: '1.5em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                paddingBottom: 10
            }
        },
        close: {
            cursor: 'pointer',
            width: '2em',
            '@media screen and (max-width: 768px) and (orientation: portrait)': {
                width: 24
            }
        }
    })
);
