import { type ClassNameMap } from '@mui/styles';
import React, { type JSX } from 'react';

import { smallButtonStyle } from './small-button-style';

interface SmallButtonProps {
    disabled?: boolean;
    title?: number;
    isActive?: boolean;
    onClick?: () => void;
}

export default function SmallButton({
    title,
    isActive,
    onClick,
    disabled
}: SmallButtonProps): JSX.Element {
    const classes: ClassNameMap = smallButtonStyle();

    return (
        <button
            disabled={disabled}
            className={`${classes.buttonContainer} ${
                isActive ?? false ? classes.active : ''
            }`}
            onClick={onClick}
        >
            <p className={classes.title}>{title}</p>
        </button>
    );
}
