import { createSlice } from '@reduxjs/toolkit';

import { playAudio } from '../helpers/audio-play';
import {
    type FinishGameBody,
    type GameBody,
    type GetCoefficientsBody,
    type OpenCardBody,
    type StartGameBody
} from '../models';

export interface StakeInfoState {
    bet: number;
    possibleWin: number;
    stakeId: number;
}

export interface GameInfoState {
    cardsArray: string[];
    coefficients: number[];
    defaultSelectCount: number;
    nextCoefficient: number;
    favoriteCounts: number[];
    hash: string | null;
    matrixSize: [number, number];
    minesCount: number;
    minesCountList: number[];
    stake: StakeInfoState | null;
}
export interface PartnerInfoState {
    currencyId: string;
    defaultBet: number;
    maxBet: number;
    maxWin: number;
    minBet: number;
    precision: number;
}

export interface GameState {
    langText: Record<string, string>;
    currentCoefficient: number | null;
    allowAnimation: boolean;
    balance: number;
    betAmount: number;
    canPlay: boolean;
    gameInfo: GameInfoState;
    isApplicationLoading: boolean;
    isPlaceBetTextShown: boolean;
    isPlaceBetTextVisible: boolean;
    isConnected: boolean;
    isGameStarted: boolean;
    partnerInfo: PartnerInfoState;
    partnerId: number;
    playerId: number | null;
    showWinDialog: boolean;
    sound: boolean;
    token: string;
    voice: boolean;
}

const initialState: GameState = {
    currentCoefficient: null,
    allowAnimation: false,
    balance: 0,
    betAmount: '0',
    canCacheOut: false,
    canPlay: true,
    isPlaceBetTextShown: true,
    isPlaceBetTextVisible: false,
    gameInfo: {
        defaultSelectCount: 15,
        favoriteCounts: [2, 4, 6, 10],
        cardsArray: new Array(25).fill(''),
        coefficients: [],
        nextCoefficient: 0,
        hash: null,
        matrixSize: [5, 5],
        minesCount: 15,
        minesCountList: new Array(20).fill(0),
        stake: null
    },
    isApplicationLoading: true,
    isConnected: false,
    isGameStarted: false,
    partnerInfo: {
        currencyId: 'FUN',
        defaultBet: 0,
        maxBet: 0,
        maxWin: 0,
        minBet: 0,
        precision: 0
    },
    partnerId: 1,
    playerId: null,
    showWinDialog: false,
    sound: true,
    token: '',
    voice: true,
    ...JSON.parse(localStorage.getItem('audio') ?? '{}')
};

/*  eslint no-param-reassign: 0 */

const GameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        toggleVoice(state: GameState): void {
            state.voice = !state.voice;

            localStorage.setItem(
                'audio',
                JSON.stringify({ sound: state.sound, voice: state.voice })
            );
        },
        toggleSound(state: GameState): void {
            state.sound = !state.sound;

            localStorage.setItem(
                'audio',
                JSON.stringify({ sound: state.sound, voice: state.voice })
            );
        },
        toggleConnection(
            state: GameState,
            { payload }: { payload: boolean; type: string }
        ): void {
            state.isConnected = payload;
        },
        initializeGame(
            state: GameState,
            {
                payload: { balance, gameInfo, partnerInfo, playerId, token, langText }
            }: { payload: GameBody; type: string }
        ): void {
            state.gameInfo = {
                ...gameInfo,
                stake: null,
                cardsArray: new Array(
                    gameInfo.matrixSize[0] * gameInfo.matrixSize[1]
                ).fill(''),
                minesCountList: new Array(
                    gameInfo.matrixSize[0] * gameInfo.matrixSize[1] - 1
                )
                    .fill(0)
                    .map((_, index: number) => index + 1)
                    .filter((item: number) =>
                        !gameInfo.favoriteCounts?.includes(item)
                    )
            };

            state.langText = langText;
            state.balance = balance;
            state.token = token;
            state.playerId = playerId;
            state.partnerInfo = partnerInfo;
            state.betAmount = partnerInfo.defaultBet;
            state.isApplicationLoading = false;
        },
        startGame(
            state: GameState,
            { payload: { matrixSize, hash } }: { payload: StartGameBody; type: string }
        ): void {
            state.gameInfo.hash = hash;
            state.isPlaceBetTextShown = false;

            if (
                state.gameInfo.matrixSize[0] !== matrixSize[0] ||
                state.gameInfo.matrixSize[1] !== matrixSize[1]
            ) {
                state.gameInfo.matrixSize = matrixSize;

                state.gameInfo.cardsArray = new Array(
                    matrixSize[0] * matrixSize[1]
                ).fill('');

                state.gameInfo.minesCountList = new Array(
                    matrixSize[0] * matrixSize[1] - 1
                ).fill('');
            }

            state.isGameStarted = true;

            if (state.voice) {
                // playAudio('makeItHappen');
            }

            if (state.sound) {
                playAudio('placeBet');
            }
        },
        updateCoefficients(
            state: GameState,
            {
                payload: { minesCount, nextCoefficient, coefficients }
            }: { payload: GetCoefficientsBody; type: string }
        ): void {
            state.gameInfo.minesCount = minesCount;
            state.gameInfo.nextCoefficient = nextCoefficient;
            state.gameInfo.coefficients = coefficients;
        },
        updateBetAmount(
            state: GameState,
            { payload }: { payload: number; type: string }
        ): void {
            state.betAmount = payload;
        },
        toggleAllowAnimation(
            state: GameState,
            { payload }: { payload: boolean; type: string }
        ): void {
            state.allowAnimation = payload;
        },

        openCard(
            state: GameState,
            {
                payload: {
                    balance,
                    nextCoefficient,
                    stake: { bet, possibleWin, stakeId, step }
                }
            }: { payload: OpenCardBody; type: string }
        ): void {
            const cardsArrayCopy: string[] = [...state.gameInfo.cardsArray];

            cardsArrayCopy[step?.boxNumber ?? 0] = `${
                step?.type ?? 'diamond'
            } open bounceIn`;

            state.balance = balance;
            state.gameInfo.nextCoefficient = nextCoefficient;

            state.gameInfo.stake = {
                bet,
                possibleWin: possibleWin ?? 0,
                stakeId
            };

            if (state.sound) {
                playAudio(step?.type ?? 'diamond');
            }

            state.gameInfo.cardsArray = cardsArrayCopy;
        },
        finishGame(
            state: GameState,
            {
                payload: {
                    balance,
                    currentCoefficient,
                    stake: { gameBoard, win, step }
                }
            }: { payload: FinishGameBody; type: string }
        ): void {
            const cardsArrayCopy: string[] = [...state.gameInfo.cardsArray];

            state.isGameStarted = false;
            state.currentCoefficient = currentCoefficient;
            state.balance = balance;
            state.canPlay = false;

            if (!step && win !== 0) {
                state.allowAnimation = true;
                state.showWinDialog = true;

                if (state.gameInfo.stake?.possibleWin != null) {
                    state.gameInfo.stake.possibleWin = win ?? 0;
                }

                if (state.voice) {
                    playAudio('win');
                }

                if (state.sound) {
                    playAudio('cashOut');
                }
            } else {
                cardsArrayCopy[step?.boxNumber ?? 0] = `${
                    step?.type ?? 'mine'
                } open bounceIn`;

                if (state.sound) {
                    playAudio(step?.type ?? 'mine');
                }

                if (state.voice) {
                    // playAudio('lose');
                }
            }

            state.gameInfo.cardsArray = cardsArrayCopy.map((item, index) => {
                if (item === '') {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    return `${gameBoard?.[index] ?? ''} bounceIn`;
                }

                return item;
            });
        },
        resetGame(state: GameState): void {
            state.gameInfo.cardsArray = new Array(25).fill('');
            state.showWinDialog = false;
            state.currentCoefficient = null;
            state.isPlaceBetTextShown = true;
            state.canPlay = true;
            state.gameInfo.nextCoefficient = state.gameInfo.coefficients[0];
            state.gameInfo.stake = null;
        },
        togglePlaceBetTextShown(state: GameState): void {
            state.isPlaceBetTextVisible = !state.isPlaceBetTextVisible;
        }
    }
});

export const {
    toggleVoice,
    toggleSound,
    toggleConnection,
    initializeGame,
    updateCoefficients,
    toggleAllowAnimation,
    openCard,
    updateBetAmount,
    finishGame,
    startGame,
    togglePlaceBetTextShown,
    resetGame
} = GameSlice.actions;

export default GameSlice.reducer;
