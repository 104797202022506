import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../utils/colors';

export const gameSectionStyle = makeStyles(() =>
    createStyles({
        game_section: {
            width: '100%',
            marginRight: ' 0.1334em'
        },
        game_section_header: {
            width: '100%',
            height: '3.2em',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '1.3334em',
            backgroundColor: colors.fog_of_war,
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'
        },
        game_section_header_title: {
            color: colors.cold_wind,
            fontSize: '1.3334em',
            lineHeight: 'normal'
        },
        game_body: {
            width: '100%',
            display: 'flex',
            background: ' radial-gradient(89.59% 89.59% at 51.21% 11.61%, #1F1043 60.37%, rgba(31, 16, 67, 0) 100%)',
            marginTop: '0.1334em',
            height: 'calc(100% - 3.3334em)'
        },
        left_image: {
            width: '25.667em',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            backgroundSize: '17.8666em'
        },
        card_wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            // paddingTop: ' 1em',
            columnGap: '0.5334em',
            rowGap: '0.5334em',
            width: '35.467em',
            height: '35.467em',
            marginTop: '2.93333em',
            position: 'relative'
        },
        bet_bar: {
            marginTop: '1.1333em',
            marginLeft: '4.53331em'
        },
        pop_up: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        pop_up_block: {
            width: '13.875em',
            height: '8.375em',
            backgroundColor: ' rgba(29, 15, 64, 0.6);',
            borderRadius: '16px',
            backdropFilter: ' blur(12px)',
            border: `solid 1px ${colors.shade_of_lavender}`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
        },
        pop_up_title: {
            fontSize: '2em',
            color: colors.shade_of_lavender,
            marginBottom: '0.3125'
        },
        pop_up_info: {
            fontSize: '1.5em',
            color: colors.wash_me
        },
        place_bet_to_start: {
            fontSize: '2.13em',
            color: colors.white,
            fontWeight: 'bold'
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
            game_section: {
                margin: 0
            },
            game_section_header: {
                height: '10.667em',
                paddingLeft: '2.5807em'
            },
            game_section_header_title: {
                fontSize: '6em'
            },
            game_body: {
                display: 'block',
                margin: 0,
                padding: '0 2.5807em 2.5807em'
            },
            left_image: {
                width: '42em',
                height: '50em',
                position: 'absolute',
                backgroundSize: 'contain',
                bottom: '-59em',
                zIndex: 1,
                left: '-14em'
            },
            card_wrapper: {
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0,
                columnGap: '1.290322em',
                rowGap: '1.290322em'
            },
            bet_bar: {
                position: 'relative',
                zIndex: 1,
                margin: 0,
                marginTop: '24.3548em',
                padding: 0
            },
            place_bet_to_start: {
                fontSize: '5em'
            },
            pop_up_block: {
                width: '45em',
                height: '30em'
            },
            pop_up_title: {
                fontSize: '5.5em'
            },
            pop_up_info: {
                fontSize: '4.5em'
            }
        },
        '@media screen and (max-width: 480px) and (orientation: portrait)': {
            game_section_header: {
                paddingLeft: 0,
                backgroundColor: 'transparent'
            },
            game_body: {
                padding: '0'
            },
            card_wrapper: {
                columnGap: '2em',
                rowGap: '2em'
            }
        }
    })
);
