import { type ClassNameMap } from '@mui/styles';
import React, { type JSX, useState } from 'react';

import { selectStyle } from './select-style';
import dropDownIcon from '../../assets/images/drop_down.svg';

interface SelectProps {
    disabled?: boolean;
    defaultCount?: string | number;
    isSelected: boolean;
    value: number;
    items?: number[];
    onSelect?: (event: number) => void;
}

export default function Select({ defaultCount, items, onSelect, disabled, isSelected, value }: SelectProps): JSX.Element {
    const classes: ClassNameMap = selectStyle();

    const [dropDown, setDropDown] = useState(false);

    return (
        <button
            disabled={disabled}
            className={`${classes.selectContainer} ${isSelected ?? false ? classes.active : ''}`}
            onClick={(): void => {
                setDropDown(!dropDown);
            }}
        >
            <p className={classes.title}>{isSelected ? value : defaultCount}</p>
            <img src={dropDownIcon} alt={'img'} className={classes.image} />
            {dropDown && (
                <div className={classes.dropDown}>
                    <div className={classes.triangle}></div>
                    <div className={classes.dropDownItemsContainer}>
                        {
                            (items ?? []).map((item: number) => (
                                <p className={classes.list} key={item} onClick={() => {
                                    onSelect?.(item);
                                }}>{item}</p>
                            ))
                        }
                    </div>
                </div>
            )}
        </button>
    );
}
