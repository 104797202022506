import { type ClassNameMap } from '@mui/styles';
import React, { type JSX } from 'react';

import { cardStyle } from './card-style';

interface CardProps {
    disabled?: boolean;
    width: string;
    height: string;
    status?: string;
    onclick?: () => any;
}

export default function Card(props: CardProps): JSX.Element {
    const classes: ClassNameMap = cardStyle();

    return (
        <button
            disabled={props.disabled}
            className={`${classes.card}  ${props.status ?? ''}`}
            style={{ width: props.width, height: props.height }}
            onClick={props.onclick}
        ></button>
    );
}
