import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../utils/colors';

export const settingsStyle = makeStyles(() =>
    createStyles({
        settings_box: {
            position: 'relative'
        },
        settings_pop_up: {
            width: '9.875em',
            fontSize: '0.93em',
            color: colors.cold_wind,
            backgroundColor: colors.corbeau,
            borderRadius: '8px',
            position: 'absolute',
            left: '-6.8125em',
            top: '3.1875em'
        },
        settings_box_item: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.625em'
        },
        settings_button: {
            backgroundSize: '1.6em',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: colors.fog_of_war,
            fontSize: 'unset',
            width: '3.2em',
            height: '3.2em',
            cursor: 'pointer',
            marginBottom: '0.1334em',
            borderTopRightRadius: '0.26em',
            borderBottomRightRadius: '0.26em'
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
            settings_container: {
                display: 'flex',
                position: 'absolute',
                right: 0
            },
            settings_button: {
                backgroundColor: 'transparent',
                backgroundSize: '6.667em',
                margin: 0,
                width: '10.667em',
                height: '10.667em'
            },
            settings_pop_up: {
                width: '55.914em',
                right: 0,
                top: 'auto',
                left: 'auto'
            },
            settings_box_item_text: {
                fontSize: '5em'
            },
            settings_box_item: {
                padding: '2.8675em'
            }
        }
    })
);
