import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../utils/colors';

export const menuStyle = makeStyles(() =>
    createStyles({
        menuContainer: {
            padding: '1.06em',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.the_counts_black,
            borderRadius: '12px',
            width: '23.2em'
        },
        title: {
            color: colors.cold_wind,
            fontSize: ' 0.93em',
            marginBottom: '0.5em'
        },
        buttonsWrapper: {
            justifyContent: 'space-between',
            display: 'flex',
            columnGap: '8px'
        },
        infoWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.06em'
        },
        button: {
            width: '100%',
            height: '2.8em',
            // padding: '0.66em 0',
            borderRadius: '4px',
            color: colors.white,
            fontSize: '1.06em',
            fontWeight: 'bold',
            marginTop: '0.96em',
            backgroundColor: colors.blue,
            cursor: 'pointer',
            '&:hover:enabled': {
                backgroundColor: colors.epic_blue
            },
            '&:disabled': {
                opacity: 0.5
            }
        },
        stake: {
            color: colors.cold_wind,
            fontSize: '0.93em'
        },
        info: {
            color: colors.white,
            fontSize: '0.93em'
        },
        inputWrapper: {
            width: '100%',
            height: '3.1em',
            padding: '0 0.8em',
            border: `0.0625em solid ${colors.one_year_of_rain}`,
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.7em'
        },
        fun: {
            color: colors.white,
            opacity: '60%',
            fontSize: '0.86em'
        },
        input: {
            paddingRight: '0.3em',
            fontWeight: 'bold',
            fontSize: '0.86em',
            backgroundColor: 'transparent',
            width: 'inherit',
            height: '100%',
            color: colors.cold_wind
        },
        countBox: {
            // flex: 'auto',
            width: '50%',
            height: '2.971em',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: colors.yankees_Blue,
            color: colors.cold_wind,
            fontWeight: 'bold',
            padding: '0 0.8em 0 1.06em'
        },
        countBoxTitle: {
            fontSize: '0.93em'
        },
        countBoxImage: {
            width: '1.5em'
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
            menuContainer: {
                width: '100%',
                padding: '1.5em'
            },
            title: {
                fontSize: '2.25806em'
            },
            stake: {
                fontSize: '2.25806em'
            },
            countBox: {
                height: '9.954em'
            },
            countBoxTitle: {
                fontSize: '2.25806em'
            },
            info: {
                fontSize: '2.25806em'
            },
            infoWrapper: {
                padding: '3em 0'
            },
            fun: {
                fontSize: '2.5em'
            },
            inputWrapper: {
                height: '8.71em'
            },
            input: {
                fontSize: '2.5em'
            },
            button: {
                fontSize: '2.58064em'
            }
        },
        '@media screen and (max-width: 480px) and (orientation: portrait)': {
            menuContainer: {
                width: '116em',
                flexDirection: 'column-reverse',
                marginLeft: '-4em'
            },
            title: {
                margin: '1.2143em 0 0.7857em',
                fontSize: '4.66666em'
            },
            inputWrapper: {
                height: '14.6667em',
                padding: '0 5.3334em',
                borderRadius: '4px'
            },
            stake: {
                fontSize: '4.66666em'
            },
            countBoxTitle: {
                fontSize: '4.66666em'
            },
            info: {
                fontSize: '4.66666em'
            },
            fun: {
                fontSize: '4.33333em'
            },
            input: {
                fontSize: '4.33333em'
            },
            button: {
                fontSize: '5.33333em',
                height: '2.7501em'
            },
            countBox: {
                height: '14.667em',
                padding: '0 5.3334em'
            },
            countBoxImage: {
                width: '6em'
            }
        }
    })
);
