import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../utils/colors';

export const selectStyle = makeStyles(() =>
    createStyles({
        selectContainer: {
            fontSize: 'inherit',
            width: '7em',
            height: '2.971em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 0.8125em',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: colors.manganese_black,
            position: 'relative',
            '&:hover:enabled': {
                backgroundColor: '#4A318A'
            }
        },
        active: {
            backgroundColor: '#4A318A'
        },
        title: {
            fontWeight: 'bold',
            color: colors.cold_wind,
            fontSize: '0.93em'
        },
        dropDown: {
            backgroundColor: colors.manganese_black,
            position: 'absolute',
            zIndex: 999,
            left: 0,
            top: '3.971em'
        },
        dropDownItemsContainer: {
            overflowY: 'scroll',
            width: '5.5em',
            height: '12.5em',
            backgroundColor: colors.manganese_black,
            '&::-webkit-scrollbar': {
                width: 1
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: colors.the_counts_black
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: colors.blue,
                borderRadius: 2
            }
        },
        triangle: {
            position: 'absolute',
            left: 'calc(50% - 0.5em)',
            zIndex: '-1',
            top: '-0.5em',
            transform: 'rotate(45deg)',
            backgroundColor: colors.manganese_black,
            width: '1em',
            height: '1em'
        },
        image: {
            width: '1.3125em'
        },
        list: {
            fontWeight: 'bold',
            color: colors.cold_wind,
            fontSize: '0.93em',
            padding: '0.75em',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#4A318A'
            }
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
            selectContainer: {
                width: '18.871em',
                height: '8.71em',
                padding: '1.6129em 2.9032em'
            },
            dropDown: {
                top: '-102em'
            },
            dropDownItemsContainer: {
                width: '18.871em',
                height: '100em'
            },
            title: {
                fontSize: '2.25806em'
            },
            triangle: {
                width: '2.25806em',
                height: '2.25806em',
                top: 'auto',
                bottom: '-1em'
            },
            list: {
                fontSize: '2.25806em'
            },
            image: {
                width: '4em'
            }
        },
        '@media screen and (max-width: 480px) and (orientation: portrait)': {
            selectContainer: {
                width: '36.667em',
                height: '14.667em'
            },
            title: {
                fontSize: '4.66666em'
            },
            list: {
                fontSize: '4.66666em'
            },
            dropDownItemsContainer: {
                width: '36.667em'
            }
        }
    })
);
