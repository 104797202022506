export const colors: Record<string, string> = {
    black: '#000000',
    white: '#ffffff',
    the_counts_black: '#27174F',
    cold_wind: '#E1E3E6',
    manganese_black: '#352266',
    blue: '#2387FF',
    epic_blue: '#0092FF',
    one_year_of_rain: '#4F397D',
    corbeau: '#1D113C',
    hei_se_black: '#3C2A63',
    elite_blue: '#101C2E',
    crocus_Purple: '#8E6CFF',
    pool_water: '#FFD70C',
    kon: '#27174F',
    masterpiece: '#A0A5AD',
    shade_of_lavender: '#8E6CFF',
    overtake: '#132136d4',
    fog_of_war: '#1F1043',
    spiro_disco_ball: '#0CB4FE',
    wash_me: '#FAFAFF',
    yankees_Blue: '#352266',
    cool_Balaclavas_Are_Forever: '#27174F',
    manganese_Black: '#311D63',
    blue_Oar: '#687C9B',
    monaco_blue: '#284572',
    gold: '#FFB800'

};
