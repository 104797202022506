import { type ClassNameMap } from '@mui/styles';
import React, { type JSX } from 'react';

import { descriptionModalStyle } from './description-modal-style';
import closeIcon from '../../../assets/images/rulesClose.svg';

interface DescriptionModalProps {
    onClose: () => void;
    description: string;
}

export default function DescriptionModal({ onClose, description }: DescriptionModalProps): JSX.Element {
    const classes: ClassNameMap = descriptionModalStyle();

    return (
        <div className={classes.container}>
            <div className={classes.modalHeader}>
                <p className={classes.title}>Minesweeper</p>
                <img className={classes.close} onClick={onClose} src={closeIcon} alt="icon"/>
            </div>
            <div className={classes.modalBody}>
                <p className={classes.text}>{description}</p>
            </div>
        </div>
    );
}
