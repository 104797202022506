import { type ClassNameMap } from '@mui/styles';
import React, { type JSX } from 'react';
import { useSelector } from 'react-redux';

import { attentionModalStyle } from './attention-modal-style';
import coins from '../../../assets/images/coins.svg';
import { type GameState } from '../../../store/gameSlice';

export default function BetLimitModal({ onCancel, header, content }: { onCancel?: () => void; header: string; content: string }): JSX.Element {
    const classes: ClassNameMap = attentionModalStyle();

    const { langText } = useSelector(
        (state: { game: GameState }) => state.game
    );

    return (
        <div className={classes.container}>
            <img className={classes.icon} src={coins} alt={'icon'}/>
            <p className={classes.title}>{header}</p>
            <p className={classes.info}>{content}</p>
            <button className={classes.reload_button} onClick={onCancel}>{langText?.ok ?? 'Ok'}</button>
        </div>
    );
}
