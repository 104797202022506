import { createStyles, makeStyles } from '@mui/styles';

import explosion from '../../assets/images/data.svg';
import decoration from '../../assets/images/decoration2.svg';
import decoration2Hover from '../../assets/images/decoration2Hover.svg';
import diamondDecoration from '../../assets/images/dimondDecoration.png';
import mineDecoration from '../../assets/images/explosiveDecoration.png';
import { colors } from '../../utils/colors';

export const cardStyle = makeStyles(() =>
    createStyles({
        card: {
            backgroundImage: `url(${decoration})`,
            fontSize: 'inherit',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '20%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px #190B38, 0px 5px 0px #190B38',
            backgroundColor: colors.cool_Balaclavas_Are_Forever,
            transition: 'background-color .1s ease-in-out, background-image .1s ease-in-out',
            borderRadius: '4px',
            '&:hover:enabled': {
                backgroundColor: colors.manganese_Black,
                backgroundImage: `url(${decoration2Hover})`
            },
            '&.diamond': {
                backgroundImage: `url(${diamondDecoration})`,
                backgroundSize: '130%',
                backgroundPositionY: '23%',
                opacity: 0.3,
                '&.open': {
                    opacity: 1
                }
            },
            '&.mine': {
                backgroundPosition: 'center center, center center',
                backgroundSize: 'contain, 200%',
                backgroundImage: `url(${mineDecoration})`,
                backgroundColor: 'rgba(255, 35, 35, 0.2)',
                opacity: 0.3,
                '&.open': {
                    backgroundImage: `url(${mineDecoration}),url(${explosion})`,
                    opacity: 1
                }
            }
        }
    })
);
