// const isMobileBrowser: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     navigator.userAgent
// );

export default function calculateFont(): number {
    const i: number = (16 * window.innerWidth) / 1920;
    const u: number = (16 * window.innerHeight) / 1050;
    const calculatedResult: number = u > i ? i : u;

    return Math.round(10 * calculatedResult) / 10;
}
