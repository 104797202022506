import { createStyles, makeStyles } from '@mui/styles';

import { colors } from '../../utils/colors';

export const smallButtonStyle = makeStyles(() =>
    createStyles({
        buttonContainer: {
            fontSize: 'inherit',
            width: '2.971em',
            height: '2.971em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            cursor: 'pointer',
            backgroundColor: colors.manganese_black,
            '&:hover:enabled': {
                backgroundColor: '#4A318A'
            }
        },
        active: {
            backgroundColor: '#4A318A'
        },
        title: {
            fontWeight: 'bold',
            color: colors.cold_wind,
            fontSize: '0.93em'
        },
        '@media screen and (max-width: 768px) and (orientation: portrait)': {
            buttonContainer: {
                width: '15.484em',
                height: '8.71em'
            },
            title: {
                fontSize: '2.25806em'
            }
        },
        '@media screen and (max-width: 480px) and (orientation: portrait)': {
            buttonContainer: {
                width: '14.667em',
                height: '14.667em'

            },
            title: {
                fontSize: '4.66666em'
            }
        }
    })
);
